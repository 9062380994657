import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const FlatStep = styled.div`
  background-color: #d9d9d9;
  width: 40px;
  height: 4px;
  cursor: pointer;

  &[data-active='true'] {
    background-color: var(--primary);
  }
`;

export const RoundedStep = styled.div`
  background-color: #d9d9d9;
  width: 6px;
  height: 6px;
  border-radius: var(--radius-full);
  cursor: pointer;

  &[data-active='true'] {
    background-color: var(--primary);
  }
`;
