import { useMemo } from 'react';

import WordmarkSrc from '@/assets/wordmark-primary.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { OnboardingStepper } from '../OnboardingStepper/OnboardingStepper';

import {
  Asset,
  buttonDesktopStyles,
  buttonMobileStyles,
  Buttons,
  Container,
  Header,
  Left,
  Main,
  Right,
  StepperWrapper,
  TextWrapper,
} from './OnboardingIntro.styles';

export interface OnboardingStep {
  id: string;
  title: string;
  description: string;
  desktopAssetUrl: string;
  desktopAssetType: 'image' | 'video';
  mobileAssetUrl: string;
  mobileAssetType: 'image' | 'video';
}

interface OnboardingActionsProps {
  activeStepIndex: number;
  steps: OnboardingStep[];
  nextButtonLabel: string;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onChangeStep?: (newStep: OnboardingStep) => void;
}

export function OnboardingActions({
  activeStepIndex,
  steps,
  nextButtonLabel,
  onNextStep,
  onPreviousStep,
  onChangeStep,
}: OnboardingActionsProps) {
  const isMobile = useIsMobileViewport();
  const buttonStyles = isMobile ? buttonMobileStyles : buttonDesktopStyles;
  return (
    <>
      <Buttons>
        {activeStepIndex > 0 && (
          <RMButton onClick={onPreviousStep} className={buttonStyles}>
            Previous
          </RMButton>
        )}
        <RMButton background="primary" onClick={onNextStep} className={buttonStyles} autoLoading>
          {nextButtonLabel}
        </RMButton>
      </Buttons>
      {activeStepIndex > 0 && (
        <StepperWrapper>
          <OnboardingStepper
            index={activeStepIndex - 1}
            total={steps.length - 1}
            mode={isMobile ? 'rounded' : 'flat'}
            onStepPress={(index) => onChangeStep?.(steps[index + 1])}
          />
        </StepperWrapper>
      )}
    </>
  );
}

interface OnboardingIntroProps {
  steps: OnboardingStep[];
  activeStep: OnboardingStep;
  title?: string;
  finishButtonLabel?: string;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onChangeStep?: (newStep: OnboardingStep) => void;
}

export function OnboardingIntro({
  steps,
  activeStep,
  title = 'WELCOME TO REMENTO',
  finishButtonLabel = 'Finish',
  onNextStep,
  onPreviousStep,
}: OnboardingIntroProps) {
  const activeStepIndex = useMemo(() => steps.findIndex((s) => s.id === activeStep.id), [activeStep, steps]);
  const nextButtonLabel = useMemo(() => {
    if (activeStepIndex === 0) {
      return 'Get started';
    }
    if (activeStepIndex === steps.length - 1) {
      return finishButtonLabel;
    }
    return 'Next';
  }, [activeStepIndex, finishButtonLabel, steps.length]);

  const isMobile = useIsMobileViewport();
  const assets = useMemo(() => {
    if (isMobile) {
      return steps.map((step) => ({
        id: step.id,
        assetUrl: step.mobileAssetUrl,
        assetType: step.mobileAssetType,
      }));
    }

    return steps.map((step) => ({
      id: step.id,
      assetUrl: step.desktopAssetUrl,
      assetType: step.desktopAssetType,
    }));
  }, [steps, isMobile]);

  return (
    <Container>
      <Header>
        <img src={WordmarkSrc} alt="Remento wordmark" />
      </Header>

      <Main>
        <Left>
          {/* Prefetching all images at once so they are loaded when the user goes through the steps */}
          {assets.map((asset) => (
            <Asset key={asset.id} hidden={asset.id !== activeStep.id}>
              {asset.assetType === 'image' ? (
                <img src={asset.assetUrl} alt="Onboarding image" />
              ) : (
                <video src={asset.assetUrl} autoPlay loop playsInline muted disablePictureInPicture />
              )}
            </Asset>
          ))}
        </Left>
        <Right>
          <RMText type="sans" size={isMobile ? 'xxs' : 'xs'} bold trackedOutCaps color="primary-decorative">
            {title}
          </RMText>
          <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
          <TextWrapper data-mobile={isMobile}>
            <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
              {activeStep.title}
            </RMText>
            <RMSpacer spacing={isMobile ? 'md' : 'xl'} direction="column" />
            <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
              {activeStep.description}
            </RMText>
          </TextWrapper>
          {!isMobile && <RMSpacer spacing="2xl" direction="column" />}

          {!isMobile && (
            <OnboardingActions
              activeStepIndex={activeStepIndex}
              steps={steps}
              nextButtonLabel={nextButtonLabel}
              onNextStep={onNextStep}
              onPreviousStep={onPreviousStep}
            />
          )}
        </Right>
      </Main>
      {isMobile && (
        <OnboardingActions
          activeStepIndex={activeStepIndex}
          steps={steps}
          nextButtonLabel={nextButtonLabel}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      )}
    </Container>
  );
}
