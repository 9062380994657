import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--border-hairline);

  padding: var(--spacing-2xl) var(--spacing-3xl);
  body[data-mobile='true'] & {
    padding: var(--spacing-xl) var(--spacing-md);
  }

  > img {
    height: 1.5rem;

    body[data-mobile='true'] & {
      height: 1rem;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-flow: row;

  body[data-mobile='true'] & {
    flex-flow: column;
  }
`;

export const Left = styled.div`
  display: flex;
  width: 50%;
  max-height: unset;
  max-width: 1280px;

  body[data-mobile='true'] & {
    width: 100%;
    max-height: 250px;
    max-width: unset;
  }
`;

export const Asset = styled.div`
  height: 100%;
  width: 100%;

  > img,
  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  width: 50%;
  max-width: 656px;
  padding: var(--spacing-5xl);

  body[data-mobile='true'] & {
    justify-content: flex-start;
    width: 100%;
    max-width: unset;
    padding: var(--spacing-lg) var(--spacing-md);
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  margin: var(--spacing-sm) auto;
  body[data-mobile='true'] & {
    gap: var(--spacing-sm);
    width: calc(100% - var(--spacing-sm) * 2);
  }
`;

export const StepperWrapper = styled.div`
  margin: var(--spacing-5xl) 0 0;
  body[data-mobile='true'] & {
    margin: 0 auto var(--spacing-sm) auto;
  }
`;

export const TextWrapper = styled.div`
  min-height: 250px;

  &[data-mobile='true'] {
    min-height: auto;
  }
`;

export const buttonMobileStyles = css`
  width: 100%;
`;

export const buttonDesktopStyles = css`
  min-width: 180px;
`;
