import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

import { OnboardingStepType } from './onboarding.types';

export function useOnboardingSteps(type: OnboardingStepType | null) {
  const { onboardingService } = useServices();
  return useQuery({
    enabled: type != null,
    queryKey: ['onboarding-step', type],
    queryFn: () => onboardingService.getOnboardingSteps(type as OnboardingStepType),
  });
}
