import { useMemo } from 'react';

import { Container, FlatStep, RoundedStep } from './OnboardingStepper.styles';

export interface OnboardingStepperProps {
  index: number;
  total: number;
  mode: 'rounded' | 'flat';
  onStepPress: (index: number) => void;
}

export function OnboardingStepper({ index: currentIndex, total, mode, onStepPress }: OnboardingStepperProps) {
  const steps = useMemo(() => new Array(total).fill(''), [total]);

  return (
    <Container>
      {steps.map((_, index) =>
        mode === 'rounded' ? (
          <RoundedStep key={index} data-active={currentIndex === index} onClick={() => onStepPress(index)} />
        ) : (
          <FlatStep key={index} data-active={currentIndex === index} onClick={() => onStepPress(index)} />
        ),
      )}
    </Container>
  );
}
